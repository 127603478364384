import { FC, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import block from 'utils/bem-css-module';
import { getIsMobile } from 'utils/common';

import { useScreenSize } from 'hooks/use-screen-size';

import style from './hint.scss';

type Props = {
    title?: string;
    children: ReactNode;
    className?: string;
    horizontal?: 'left' | 'right';
    vertical?: 'top' | 'bottom';
}


const b = block(style);

export const Hint: FC<HTMLAttributes<HTMLElement> & Props> = ({
	title,
	children,
	className = '',
	vertical = 'top',
	horizontal = 'left',
	...rest
}) =>  {
	const hint = useRef<HTMLElement>(null);
	const [hor, setHor] = useState(horizontal);
	const { width } = useScreenSize();
	const isMobile = getIsMobile(width);

	useEffect(() => {
		if (!hint || !hint.current) {
			return;
		}
		const { width, left } = hint.current.getBoundingClientRect();
		if (horizontal === 'left' && left + width > window.innerWidth) {
			setHor('right');
			hint.current.style.marginLeft = `calc(${-width}px + 4.2rem)`;
		}
	}, [hint, horizontal]);

	return (
		<aside
			ref={hint}
			className={b({ mobile: isMobile, ver: vertical, hor }).mix(className)}
			{...rest}
		>
			{title && <h1 className={b('h1')}>{title}</h1>}
			{children}
			<div className={b('tail')} />
		</aside>
	);
};

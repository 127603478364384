export const MOBILE_WIDTH = 500;
export const YANDEX_METRIC_ID = 45654081;
export const FEEDBACK_COOKIE = 'operation-feedback';
export const FEEDBACK_THEMES = {
	OPERATION: 'after_document_printing',
};
export const SB_ORIGIN = 'sb.printbox.io';
export const MAIN_ORIGIN = 'printbox.io';
export const AGREEMENT_SESSION_FLAG = 'agreement_session_flag';
export const DAYS = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
export const DATE_OPTIONS = {
	month: 'long',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
} as const;
export const GOOGLE_DRIVE_ID = '173261272025-91kja12m0jka9o66droelda2203semp7.apps.googleusercontent.com';
